import React, { Component } from 'react';
import './CSS/LinksUteis.css';

import Header from '../Components/Header';
import Footer from '../Components/Footer';

export class LinksUteis extends Component {
    render() {
        return (
            <div className='bg'>
                <Header/>
                
                <div className='lu-holder'>
                    <div>
                        <div className='lu-title'><span>LINKS ÚTEIS</span></div>
                        <div>
                            <ul>
                                <p><b>OAB (Ordem dos Advogados do Brasil)</b></p>
                                <li><a href='https://www.oab.org.br/' target='_blank' rel='noopener noreferrer'>Conselho Federal</a></li>
                                <li><a href='https://www.oab.org.br/seccionais.asp' target='_blank' rel='noopener noreferrer'>Seccionais da OAB nos estados</a></li>
                                <p><b>PARTE GERAL</b></p>
                                <li><a href='https://www.gov.br/agu/pt-br' target='_blank' rel='noopener noreferrer'>Advocacia Geral da União</a></li>
                                <li><a href='https://www.cjf.jus.br/cjf' target='_blank' rel='noopener noreferrer'>Conselho da Justiça Federal</a></li>
                                <li><a href='https://www.cnj.jus.br/' target='_blank' rel='noopener noreferrer'>Conselho Nacional de Justiça</a></li>
                                <li><a href='https://www.cnmp.mp.br/portal/' target='_blank' rel='noopener noreferrer'>Conselho Nacional do Ministério Público</a></li>
                                <li><a href='https://www.in.gov.br/inicio' target='_blank' rel='noopener noreferrer'>Diário Oficial da União</a></li>
                                <li><a href='https://comunica.pje.jus.br/' target='_blank' rel='noopener noreferrer'>Diário de Justiça Eletrônico Nacional - DJEN</a></li>
                                <li><a href='https://portal.tcu.gov.br/' target='_blank' rel='noopener noreferrer'>Tribunal de Contas da União</a></li>
                                <p><b>MINISTÉRIO PÚBLICO FEDERAL</b></p>
                                <li><a href='http://www.mpf.mp.br/pgr' target='_blank' rel='noopener noreferrer'>Procuradoria Geral da República</a></li>
                                <li><a href='http://www.mpf.mp.br/regiao1' target='_blank' rel='noopener noreferrer'>Procuradoria Regional da República – 1ª Região</a></li>
                                <li><a href='http://www.mpf.mp.br/regiao2' target='_blank' rel='noopener noreferrer'>Procuradoria Regional da República – 2ª Região</a></li>
                                <li><a href='http://www.mpf.mp.br/regiao3' target='_blank' rel='noopener noreferrer'>Procuradoria Regional da República – 3ª Região</a></li>
                                <li><a href='http://www.mpf.mp.br/regiao4' target='_blank' rel='noopener noreferrer'>Procuradoria Regional da República – 4ª Região</a></li>
                                <li><a href='http://www.mpf.mp.br/regiao5' target='_blank' rel='noopener noreferrer'>Procuradoria Regional da República – 5ª Região</a></li>
                                <p><b>MINISTÉRIO PÚBLICO ESTADUAL</b></p>
                                <li><a href='https://www.mpac.mp.br/' target='_blank' rel='noopener noreferrer'>Acre</a></li>
                                <li><a href='https://www.mpal.mp.br/' target='_blank' rel='noopener noreferrer'>Alagoas</a></li>
                                <li><a href='https://www.mpam.mp.br/' target='_blank' rel='noopener noreferrer'>Amazonas</a></li>
                                <li><a href='https://www.mpba.mp.br/' target='_blank' rel='noopener noreferrer'>Bahia</a></li>
                                <li><a href='https://www.mpce.mp.br/' target='_blank' rel='noopener noreferrer'>Ceará</a></li>
                                <li><a href='https://www.mpdft.mp.br/' target='_blank' rel='noopener noreferrer'>Distrito Federal</a></li>
                                <li><a href='https://www.mpes.mp.br/' target='_blank' rel='noopener noreferrer'>Espírito Santo</a></li>
                                <li><a href='https://www.mpgo.mp.br/' target='_blank' rel='noopener noreferrer'>Goiás</a></li>
                                <li><a href='https://www.mpma.mp.br/' target='_blank' rel='noopener noreferrer'>Maranhão</a></li>
                                <li><a href='https://www.mpmt.mp.br/' target='_blank' rel='noopener noreferrer'>Mato Grosso</a></li>
                                <li><a href='https://www.mpms.mp.br/' target='_blank' rel='noopener noreferrer'>Mato Grosso do Sul</a></li>
                                <li><a href='https://www.mpmg.mp.br/' target='_blank' rel='noopener noreferrer'>Minas Gerais</a></li>
                                <li><a href='https://www.mppa.mp.br/' target='_blank' rel='noopener noreferrer'>Pará</a></li>
                                <li><a href='https://www.mppr.mp.br/' target='_blank' rel='noopener noreferrer'>Paraná</a></li>
                                <li><a href='https://www.mppe.mp.br/' target='_blank' rel='noopener noreferrer'>Pernambuco</a></li>
                                <li><a href='https://www.mprj.mp.br/' target='_blank' rel='noopener noreferrer'>Rio de Janeiro</a></li>
                                <li><a href='https://www.mprs.mp.br/' target='_blank' rel='noopener noreferrer'>Rio Grande do Sul</a></li>
                                <li><a href='https://www.mpro.mp.br/' target='_blank' rel='noopener noreferrer'>Rondônia</a></li>
                                <li><a href='https://www.mprr.mp.br/' target='_blank' rel='noopener noreferrer'>Roraima</a></li>
                                <li><a href='https://www.mpsc.mp.br/' target='_blank' rel='noopener noreferrer'>Santa Catarina</a></li>
                                <li><a href='https://www.mpsp.mp.br/' target='_blank' rel='noopener noreferrer'>São Paulo</a></li>
                                <li><a href='https://www.mpto.mp.br/portal/' target='_blank' rel='noopener noreferrer'>Tocantins</a></li>
                                <p><b>PODER LEGISLATIVO FEDERAL</b></p>
                                <li><a href='http://www.senado.gov.br/' target='_blank' rel='noopener noreferrer'>Senado Federal</a></li>
                                <li><a href='http://www.camara.gov.br/' target='_blank' rel='noopener noreferrer'>Câmara dos Deputados</a></li>
                                <p><b>POLÍCIA FEDERAL</b></p>
                                <li><a href='https://www.gov.br/pf/pt-br' target='_blank' rel='noopener noreferrer'>Superintendência Nacional</a></li>
                                <li><a href='https://www.gov.br/pf/pt-br/acesso-a-informacao/institucional/quem-e-quem/superintendencias-e-delegacias' target='_blank' rel='noopener noreferrer'>Superintendências Estaduais</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
}

export default LinksUteis;
